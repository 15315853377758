import httpClient from '../httpClient'

import { formatData } from '../request'
import { encodeRequestParameters } from '../request'

import { residentsFormat } from '../formats'

// get the list of residents
export function getResidents(data) {
    data = formatData(residentsFormat,data)
    const params = encodeRequestParameters(data)
    return httpClient.get('/fiche', {params})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

// delete a particular resident
export function deleteResident(residentId) {
    return httpClient.delete('/fiche/' + residentId, {})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}